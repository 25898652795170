<template>
  <div>
    <PublicBoxMaskDialog v-if="isShowTable" DialogTitle="选择用户" :DialogShow="isShowTable" @DialogCloseEvente="closeDialogEvent" DialogWidth="1200px">
      <template slot="contentMinBox">
        <div>
          <span class="temp_class_span_div">-699</span>
          <div class="filter_search_div">
            <el-select :disabled="!!group_id" style="width: 260px; margin-right: 24px;" v-model="reqObj.group_id" filterable placeholder="请选择教研组"
              v-if="is_personid == 1">
              <el-option v-for="item in teachingGroupList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>

            <div v-if="false" class="filter_search_box">
              <el-input style="width: 260px" :clearable="true" placeholder="请输入姓名" v-model="reqObj.realname" @keyup.enter.native="handleFilter()">
                <el-button slot="append" type="primary" icon="el-icon-search" @click="handleFilter()"></el-button>
              </el-input>
            </div>

            <el-radio-group v-model="isTableShowDiv">
              <el-radio :label="1">当前所有成员</el-radio>
              <el-radio :label="2">查看已选成员 <span style="color:red">({{tabListArr_2.length}})</span> </el-radio>
            </el-radio-group>
          </div>

          <el-table v-show="isTableShowDiv==1" v-loading="listLoading"
            :data="tabListArr_1.filter(data => !search_1_val || data.realname.includes(search_1_val))" border fit
            :header-cell-style="{background:'#f2f2f2'}" highlight-current-row style="width: 100%;">
            <el-table-column prop="realname" align="center" width="55">
              <template slot="header">
                <el-checkbox v-model="isAllSelected_1" @change="selectAllChange_1"></el-checkbox>
              </template>
              <template slot-scope="{row}">
                <el-checkbox v-model="row.isSelected" @change="selectChange_1">
                </el-checkbox>
              </template>
            </el-table-column>

            <el-table-column align="left" width="180">
              <template slot="header">
                <el-input v-model="search_1_val" placeholder="输入关键字筛选" size="medium" clearable> </el-input>
              </template>
              <template slot-scope="{row}">
                <span>{{ row.realname }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="stage_name" label="学段" width="80"></el-table-column>
            <el-table-column prop="subject_name" label="学科" width="80"></el-table-column>
            <el-table-column prop="group_name" :label='is_personid == 1 ? "所属教研组" : "所属四名工作室"' :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="org_name" label="所属单位" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="area" label="所属地区" :show-overflow-tooltip="true"></el-table-column>
          </el-table>

          <el-table v-show="isTableShowDiv==2" v-loading="listLoading"
            :data="tabListArr_2.filter(data => !search_2_val || data.realname.includes(search_2_val))" border fit
            :header-cell-style="{background:'#f2f2f2'}" highlight-current-row style="width: 100%;">
            <el-table-column prop="realname" align="center" width="55">
              <template slot="header">
                <el-checkbox v-model="isAllSelected_2" @change="selectAllChange_2"></el-checkbox>
              </template>
              <template slot-scope="{row}">
                <el-checkbox v-model="row.isSelected" @change="selectChange_2">
                </el-checkbox>
              </template>
            </el-table-column>

            <el-table-column align="left" width="180">
              <template slot="header">
                <el-input v-model="search_2_val" placeholder="输入关键字筛选" size="medium" clearable> </el-input>
              </template>
              <template slot-scope="{row}">
                <span>{{ row.realname }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="stage_name" label="学段" width="80"></el-table-column>
            <el-table-column prop="subject_name" label="学科" width="80"></el-table-column>
            <el-table-column prop="group_name" label="所属教研组" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="org_name" label="所属单位" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="area" label="所属地区" :show-overflow-tooltip="true"></el-table-column>
          </el-table>
        </div>
      </template>

      <template slot="footerCententBox">
        <el-button @click="closeDialogEvent">取消</el-button>
        <el-button style="margin-left:30px;" type="primary" @click="confirmDialogEvent">保存</el-button>
      </template>
    </PublicBoxMaskDialog>
  </div>
</template>

<script>
export default {
  props: {
    // 是否打开
    isShowTable: {
      type: Boolean,
      default: false,
    },
    // 教研组id
    group_id: {
      type: [String, Number],
      default: "",
    },
    // 四名工作室id
    teaching_studio_id: {
      type: [String, Number],
      default: "",
    },
    // 指定成员选中值
    tearmCheckedList: {
      type: [Array],
      default: () => [],
    },
    // 是否使用指定人员列表
    isAppointMember: {
      type: Boolean,
      default: false,
    },
    // 指定人员id列表
    appointMember: {
      type: [Array],
      default: () => [],
    },
    // 教研组/四名工作室
    is_personid: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      isAllSelected_1: false,
      isAllSelected_2: false,
      search_1_val: "",
      search_2_val: "",
      isTableShowDiv: 1,
      teachingGroupList: [], // 教研组
      listLoading: true, //表格Loading
      reqObj: {
        page: 1,
        limit: 9999,
        realname: "",
      },
      tabListArr_1: [],
      tabListArr_2: [],
      selectedArrIds: this.tearmCheckedList,
      appointMemberObj: {
        page: 1,
        limit: 9999,
        user_ids: this.appointMember.length > 0 ? this.appointMember.join(',') : ""
      }
    }
  },
  computed: {},

  components: {},

  methods: {
    selectAllChange_1(e) {
      this.tabListArr_1.forEach(item_1 => {
        this.$set(item_1, "isSelected", e);
      });
      this.selectChange_1();
    },
    // 判断第一个全选
    selectAllChange_is_ok() {
      if (this.selectedArrIds.length == this.tabListArr_1.length && this.selectedArrIds.length) {
        this.isAllSelected_1 = true;
      } else {
        this.isAllSelected_1 = false;
      };
      if (this.selectedArrIds.length == this.tabListArr_2.length && this.selectedArrIds.length) {
        this.isAllSelected_2 = true;
      } else {
        this.isAllSelected_2 = false;
      };
    },
    selectAllChange_2(e) {
      this.tabListArr_2.forEach(item_1 => {
        this.$set(item_1, "isSelected", e);
      });
      this.selectChange_2();
    },
    // 切换选择
    selectChange_1() {
      let list_2 = this.tabListArr_1.filter(item_1 => {
        return item_1.isSelected;
      });
      this.tabListArr_2 = list_2;
      let tempArr = list_2.map(item1 => {
        return item1.id;
      });
      this.selectedArrIds = tempArr;
      this.selectAllChange_is_ok();
    },
    // 切换选择
    selectChange_2() {
      let list_2 = this.tabListArr_2.filter(item_1 => {
        return item_1.isSelected;
      });
      this.tabListArr_2 = list_2;
      let tempArr = list_2.map(item1 => {
        return item1.id;
      });
      this.selectedArrIds = tempArr;
      this.tabListArr_1.forEach(item_1 => {
        this.$set(item_1, "isSelected", false);
        this.selectedArrIds.forEach(item_2 => {
          if (item_1.id == item_2) {
            this.$set(item_1, "isSelected", true);
          };
        })
      });
      this.selectAllChange_is_ok();
    },
    //获取教研组
    getTeachingGroup() {
      // TeacherGroup/get_my_list
      this.$axios.get("/my_ground_list").then((res) => {
        let resdata = res.data;
        this.teachingGroupList = resdata.data;
      });
    },
    // 列表获取
    async handleFilter() {
      try {
        this.listLoading = true;
        let apiUrl = "";
        if (this.is_personid == 1) {
          this.reqObj.group_id = Number(this.group_id)
          apiUrl = "/get_appoint_user";
        }
        if (this.is_personid == 2) {
          this.reqObj.teaching_studio_id = Number(this.teaching_studio_id)
          apiUrl = "/get_appoint_user_teachingstudio";
        }
        let apiParams = this.reqObj;
        if (this.isAppointMember) {
          if (this.is_personid == 1) {
            this.appointMemberObj.group_id = Number(this.group_id)
            apiUrl = "/activityLink/get_appoint_user";
          }
          if (this.is_personid == 2) {
            this.appointMemberObj.teaching_studio_id = Number(this.teaching_studio_id)
            apiUrl = "/activityLink/get_appoint_user_teachingstudio";
          }
          apiParams = this.appointMemberObj;
        }

        const res = await this.$axios.get(apiUrl, { params: apiParams });
        let list_1 = res.data.data.data;
        let list_2 = [];
        list_1.forEach(item_1 => {
          this.$set(item_1, "isSelected", false);
          this.selectedArrIds.forEach(item_2 => {
            if (item_1.id == item_2) {
              this.$set(item_1, "isSelected", true);
              list_2.push(item_1);
            };
          })
        });
        this.tabListArr_1 = list_1;
        this.tabListArr_2 = list_2;
        this.selectAllChange_is_ok();
        this.$nextTick(() => {
          this.listLoading = false;
        });
      } catch (error) {
        console.log(error);
        this.$nextTick(() => {
          this.listLoading = false;
        });
      }
    },
    // 取消
    closeDialogEvent() {
      this.$emit('confirmEvent', false, null);
    },
    // 确认
    confirmDialogEvent() {
      this.$emit('confirmEvent', true, this.selectedArrIds);
    }
  },

  destroyed() {
  },

  mounted() {
    this.getTeachingGroup();
    this.handleFilter();
  },

  created() { },
}
</script>

<style lang="scss" scoped>
.filter_search_div {
  display: flex;
  margin-left: auto;
  align-items: center;
  margin-right: 24px;
  padding-bottom: 10px;
  /deep/.el-input__inner {
    background-color: #f4fbff;
  }
  .filter_search_box {
    margin-left: 24px;
    margin-right: 24px;
  }
}
/deep/.el-table__body-wrapper {
  height: calc(100vh - 324px) !important;
  overflow-y: auto !important;
}
</style>