<template>
  <div class="pb30" :class="routeName == 'TrainActivityIndexSet' ? 'w_layout' : ''">
    <div class="public-activity-box" :class="isStudio ? 'public-activity-box-studio' : ''">
      <div class="public-breadcrumb" v-if="!isStudio || routeName == 'TrainActivityIndexSet'">
        <span class="breadcrumb-before">
          当前位置：
          <router-link to="/">首页</router-link> >
          <router-link to="/activity?pageType=2&statusType=0">教研活动</router-link>
          >
        </span>
        <span class="breadcrumb-current">{{ this.$route.query.id ? '修改' : '创建' }}教研活动</span>
      </div>

      <div class="public-main-box">
        <h4 class="title">{{ this.$route.query.id ? '修改' : '创建' }}教研活动</h4>
        <el-form :model="activityInfoCreate" :rules="activityInfoCreateRules" ref="activityInfoCreateForm"
          label-width="170px" class="activity-el-form">
          <!-- prop="cover_id" -->
          <el-form-item label="活动图片：">
            <label slot="label"><span style="color: red">*</span>&nbsp;&nbsp;活动图片：</label>
            <div class="upload-box">
              <template v-if="!this.activityInfoCreate.activityInfoCover &&
    !this.activityInfoCreate.cover_id
    ">
                <div :class="index == uploadImgDefaultIndex ? 'box_img1' : 'box_img2'"
                  @click="uploadImgDefaultChange(index, item)" v-for="(item, index) in uploadImgDefaultList"
                  :key="index">
                  <img :src="item.url" alt="" width="134px" height="75px" />
                  <i class="el-icon-check dg"></i>
                </div>
              </template>
              <dc-upload style="position: relative" ref="activityUpload" :dcAutoUpload="true" :show-file-list="false"
                dcdom="activityUpload" :dcConfig="dcUploadConfig" @dc-success="dcUploadSuccess"
                @dc-progress="dcProgressTrue = false" v-if="dcUploadConfig.key && dcUploadConfig.notify_url"
                accept=".jpg,.png,.swf,.bmp,.jpeg,.JPG,.PNG,.SWF,.BMP,.JPEG">
                <div class="upload-img-box">
                  <img :src="activityInfoCreate.activityInfoCover" v-if="activityInfoCreate.cover_id"
                    class="upload-img" />
                  <i class="el-icon-plus upload-noData" v-else></i>
                </div>
              </dc-upload>
            </div>
          </el-form-item>

          <el-form-item label="活动名称：" prop="name">
            <el-input v-model="activityInfoCreate.name" placeholder="请输入教研活动名称" maxlength="20" show-word-limit
              style="width: 400px"></el-input>
          </el-form-item>
          <!-- 新增 -->
          <el-form-item label="标签：" required>
            <el-input v-model="activityInfoCreate.label" placeholder="请点击输入活动噱头、吸引人卖点等，最多50个字"
              style="width: 400px"></el-input>
          </el-form-item>
          <!-- 修改 -->
          <el-form-item label="活动类型：" required>
            <el-select v-model="activityType" placeholder="请选择活动类型" @change="activityTypeChange">
              <el-option v-for="(item, index) in activityTypeOption" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="活动描述：" prop="description">
            <div class="pr80">
              <Editor v-model="activityInfoCreate.description"></Editor>
            </div>
          </el-form-item>

          <el-form-item label="适合学段：">
            <label slot="label"><span style="color: red">*</span>&nbsp;&nbsp;适合学段：</label>
            <el-select v-model="activityInfoCreate.stage_id" placeholder="请选择学段" @change="sectionChange">
              <el-option v-for="(item, index) in sectionOption" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="适合学科：">
            <label slot="label"><span style="color: red">*</span>&nbsp;&nbsp;适合学科：</label>
            <el-select v-model="activityInfoCreate.subject_id" placeholder="请选择学科">
              <el-option v-for="(item, index) in courseOption" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="开展方式：" prop="implement_type">
            <el-select placeholder="请选择活动开展方式" v-model="activityInfoCreate.implement_type">
              <el-option v-for="(item, index) in modeOption" :key="index" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="起止时间：" prop="activityInfoTime">
            <el-date-picker v-model="activityInfoCreate.activityInfoTime" type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>

          <el-form-item v-if="ishiddenSponsor" label="活动发起人：" prop="initiator_type">
            <div class="sponsor-box">
              <el-radio-group v-model="activityInfoCreate.initiator_type" @change="sponsorChange"
                :disabled="is_disable">
                <el-radio :label="item.value" v-for="(item, index) in eventSponsor" :key="index">
                  {{ item.name }}
                </el-radio>
              </el-radio-group>
              <div class="box-right" v-if="groupName">
                <p>{{ groupName }}</p>
                <span class="sponsor-button" @click="sponsorGroupButton()"> 更换 </span>
              </div>
            </div>
            <div v-if="groupListShow && !isGroup" class="list-box">
              <el-radio-group v-model="activityInfoCreate.group_id" @change="groupChange"
                v-if="activityInfoCreate.initiator_type == 2">
                <el-radio v-for="(item, index) in teachingGroupList" :key="index" :label="item.id"
                  style="margin-bottom: 15px">
                  {{ item.name }}
                </el-radio>
              </el-radio-group>
              <el-radio-group v-model="activityInfoCreate.teaching_studio_id" @change="groupChange"
                v-if="activityInfoCreate.initiator_type == 3">
                <el-radio v-for="(item, index) in teachingGroupList" :key="index" :label="item.id"
                  style="margin-bottom: 15px">
                  {{ item.teaching_studio_name }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

          <el-form-item label="允许谁参与：" prop="permiss_range" class="permiss">
            <el-radio-group v-model="activityInfoCreate.permiss_range">
              <el-radio :label="1">所有人</el-radio>
              <!-- <el-radio :label="4">自定义</el-radio> -->
            </el-radio-group>
            <div class="participation-box" v-if="activityInfoCreate.permiss_range == 4">
              <div class="box-left">
                <el-button size="mini" @click="tearmTable()"> 选择成员 </el-button>
              </div>
              <div class="box-right">
                <p class="participation-people" @click="tearmTable()">
                  已选<span>{{ tearmTableChoiceNumber }}</span>人>>
                </p>
              </div>
            </div>
          </el-form-item>

          <!-- <el-form-item label="允许其他人报名：" prop="sign_up" v-if="activityInfoCreate.is_personid == 1">
            <el-radio-group v-model="activityInfoCreate.sign_up">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="2">关闭</el-radio>
            </el-radio-group>
          </el-form-item> -->
        </el-form>
        <!--选择指定成员弹窗-->
        <UserSelectTable v-if="tearmTableShow" :isShowTable="tearmTableShow" :tearmCheckedList="tearmCheckedList"
          :group_id="activityInfoCreate.group_id" :teaching_studio_id="activityInfoCreate.teaching_studio_id"
          :is_personid="activityInfoCreate.is_personid" @confirmEvent="confirmEvent"></UserSelectTable>
        <!--选择指定成员弹窗-->

        <div class="activity-steps-box">
          <div class="backBox mr60" @click="toBack()">上一步</div>
          <div class="nextBox" @click="save()">保存</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserSelectTable from "../components/userSelectTable.vue";
import { deepClone, queryString } from "@/utils/utils.js";
export default {
  components: {
    UserSelectTable,
  },
  data() {
    const validateDatetimerange = (rule, value, callback) => {
      if (value.length < 2) {
        callback(new Error("请选择起止时间"));
      } else {
        callback();
      }
    };
    const validateInitiator = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择活动发起人"));
      } else if (value == 2) {
        if (!this.activityInfoCreate.group_id) {
          callback(new Error("活动发起人选择了教研组，请选择指定的教研组。"));
        } else {
          callback();
        }
      } else if (value == 3) {
        if (!this.activityInfoCreate.teaching_studio_id) {
          callback(new Error("活动发起人选择了四名工作室，请选择指定的四名工作室。"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const validatePermissUsers = (rule, value, callback) => {
      if (this.tearmCheckedList.length == 0) {
        callback(new Error("请选择允许谁参与"));
      } else {
        callback();
      }
    };
    return {
      routeName: "",
      //是否禁用活动发起人
      is_disable: false,

      activityType: "", //活动类型
      activityTypeOption: [], //活动类型选项

      activityId: 0, //活动id
      submitType: "add", //提交类型(add新增 edit修改)
      isGroup: false, //当前是不是在教研组创建
      //金山云上传配置
      dcUploadConfig: {
        key: "",
        notify_url: "",
      },
      //活动信息设置参数
      activityInfoCreate: {
        activityInfoCover: "", //活动信息封面
        activityInfoTime: [], //活动信息起止时间
        cover_id: "",
        name: "",
        label: "",
        description: "",
        stage_id: "",
        subject_id: "",
        implement_type: "",
        is_time_range: "",
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
        initiator_type: "",
        // 1所有人 2本校成员 3指定教研组 4指定成员
        permiss_range: 1,
        permiss_groups: [],
        permiss_users: [],
        group_id: "",
        // 1为开启 ，2为关闭
        sign_up: 2,
        is_personid: "",
        teaching_studio_id: "",
      },
      //活动信息设置验证
      activityInfoCreateRules: {
        cover_id: [
          {
            required: true,
            message: "请上传活动图片",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入教研活动名称",
            trigger: "blur",
          },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "请输入教研活动活动描述",
            trigger: "blur",
          },
        ],
        implement_type: [
          {
            required: true,
            message: "请选择活动开展方式",
            trigger: "change",
          },
        ],
        activityInfoTime: [
          {
            required: true,
            validator: validateDatetimerange,
            trigger: "blur",
          },
        ],
        initiator_type: [
          {
            required: true,
            validator: validateInitiator,
            trigger: "change",
          },
        ],
        permiss_range: [
          {
            required: true,
            message: "请选择允许谁参与",
            trigger: "change",
          },
        ],
        sign_up: [
          {
            required: true,
            message: "是否开启其他人报名",
            trigger: "change",
          },
        ],
      },
      uploadImgDefaultList: [], //默认封面列表
      uploadImgDefaultIndex: 0, //默认封面索引
      //默认封面选中参数
      uploadImgDefault: {
        url: "",
        id: 0,
      },
      sectionOption: [], //学段选项
      courseOption: [], //学科选项
      //开展方式
      modeOption: [
        {
          name: "线上",
          value: 1,
        },
        {
          name: "线下",
          value: 2,
        },
        {
          name: "混合",
          value: 3,
        },
      ],
      //活动发起人
      eventSponsor: [
        // { name: "个人", value: 1 },
        { name: "教研组", value: 2 },
        { name: "四名工作室", value: 3 },
      ],
      groupName: "", //选中的组名
      groupListShow: false, //组列表是否显示
      tearmTableShow: false, //指定成员表格弹窗是否显示
      tearmCheckedList: [], //指定成员列表
      tearmTableChoiceNumber: 0, //指定成员选中数量
      ishiddenSponsor: false,
      teachingGroupList: [],
      isStudio: false,
    };
  },
  created() {
    this.routeName = this.$route.name
    this.$emit('rouname', this.routeName)
    //判断是不是修改
    if (this.$route.query.id) {
      this.activityId = this.$route.query.id;
      this.submitType = "edit";
      this.is_disable = true;
    }
    //判断是不是教研组
    if (this.$route.query.groupId) {
      this.isGroup = true;
      this.$set(this.activityInfoCreate, "initiator_type", 2);
      this.$set(this.activityInfoCreate, "group_id", this.$route.query.groupId);
      this.ishiddenSponsor = false;
      this.activityInfoCreate.is_personid = 1;
    } else if (this.$route.query.studioId) {
      this.isGroup = true;
      this.$set(this.activityInfoCreate, "initiator_type", 3);
      this.$set(
        this.activityInfoCreate,
        "teaching_studio_id",
        this.$route.query.studioId
      );
      this.ishiddenSponsor = false;
      this.activityInfoCreate.is_personid = 2;
      this.isStudio = true;
    } else {
      this.ishiddenSponsor = true;
    }
    //判断是不是新增
    if (this.submitType == "add") {
      this.getDefaultCover();
    }

    this.getDcUploadConfig(); //获取金山云配置
    this.getTeachingGroup(); //获取教研组列表
    this.getSection(); //获取学段
    // 获取活动类型
    this.getActivityType();
  },
  methods: {
    //获取活动类型
    async getActivityType() {
      const res = await this.$axios.get("/activity_type");
      this.activityTypeOption = res.data.data;
      this.activityType = this.activityTypeOption[0].id;
      let path = this.$route.fullPath;
      let isBack = path.indexOf("returnId");
      if (isBack != -1) {
        this.activityType = parseInt(localStorage.getItem("ActivityTypeId"));
      }
      localStorage.setItem("ActivityTypeId", this.activityType);
    },
    //活动类型更改的回调
    activityTypeChange(id) {
      localStorage.setItem("ActivityTypeId", id);
    },

    //获取金山云配置
    async getDcUploadConfig() {
      const res = await this.$axios.get("/k3sSetting");
      const data = res.data.data;
      this.dcUploadConfig.key = data.key;
      this.dcUploadConfig.notify_url = data.callBack;
    },
    //上传成功后的回调
    async dcUploadSuccess(data) {
      if (!data.upload) return;
      const resData = data.upload.CompleteMultipartUploadResult;
      const etag = resData.ETag.replace(/"/g, "");
      const res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: etag,
        },
      });
      this.activityInfoCreate.activityInfoCover = resData.Location;
      this.activityInfoCreate.cover_id = res.data.data.id;
      this.$refs["activityInfoCreateForm"].validateField("cover_id");
    },
    //获取默认封面图
    async getDefaultCover() {
      const res = await this.$axios.get("/default/activityCover");
      this.uploadImgDefaultList = res.data.data;
      this.uploadImgDefault.id = this.uploadImgDefaultList[0].id;
      this.uploadImgDefault.url = this.uploadImgDefaultList[0].url;
    },
    //默认封面选择回调
    uploadImgDefaultChange(index, item) {
      this.uploadImgDefaultIndex = index;
      this.uploadImgDefault.url = item.url;
      this.uploadImgDefault.id = item.id;
    },
    //获取教研组
    getTeachingGroup(type = "") {
      if (this.$route.query.groupId || type == 2) {
        this.$axios
          .get("/TeacherGroup/get_my_list", {
            params: {
              my_type: 1,
              audit_status: "",
            },
          })
          .then((res) => {
            this.teachingGroupList = res.data.data.data;
          });
      }
      if (this.$route.query.studioId || type == 3) {
        this.$axios
          .get("/user/TeachingStudioList", {
            params: {
              user_id: localStorage.getItem("id"),
              page: 1,
              limit: 99999,
            },
          })
          .then((res) => {
            this.teachingGroupList = res.data.data.data;
          });
      }
      // //判断是不是修改
      // if (this.submitType == "edit") {
      if (this.activityId) {
        this.getActivityInfo(); //获取活动信息
      }
      // }
    },
    //获取学段
    async getSection() {
      const res = await this.$axios.get("/stage/stage_list");
      this.sectionOption = res.data.data;
    },
    //获取学科
    async getCourse(pid) {
      const res = await this.$axios.get("/subject/list", {
        params: {
          pharseid: pid,
        },
      });
      let data = res.data.data;
      data.forEach((item) => {
        item.id = item.subjectId;
        item.name = item.subjectName;
      });
      this.courseOption = data;
    },
    //获取活动信息
    async getActivityInfo() {
      const res = await this.$axios.get(
        `/activity/edit/${this.activityId}?is_personid=${this.activityInfoCreate.is_personid}`
      );
      const data = res.data.data;
      this.activityInfoCreate.activityInfoCover = data.cover_url;
      this.activityInfoCreate.activityInfoTime = [
        `${data.start_date} ${data.start_time}`,
        `${data.end_date} ${data.end_time}`,
      ];
      this.activityInfoCreate.cover_id = data.cover_id;
      this.activityInfoCreate.name = data.name;
      this.activityInfoCreate.label = data.label;
      this.activityInfoCreate.description = data.description;
      this.activityInfoCreate.stage_id = data.stage_id ? data.stage_id : "";
      this.activityInfoCreate.subject_id = data.subject_id ? data.subject_id : "";
      this.activityInfoCreate.implement_type = data.implement_type;
      this.activityInfoCreate.is_time_range = data.is_time_range;
      this.activityInfoCreate.start_date = data.start_date;
      this.activityInfoCreate.start_time = data.start_time;
      this.activityInfoCreate.end_date = data.end_date;
      this.activityInfoCreate.end_time = data.end_time;
      this.activityInfoCreate.initiator_type = data.initiator_type;
      this.activityInfoCreate.permiss_range = data.permiss_range;
      this.activityInfoCreate.sign_up = data.sign_up;
      //活动类型
      this.activityType = data.type;
      localStorage.setItem("ActivityTypeId", this.activityType);
      this.activityInfoCreate.permiss_groups =
        typeof data.permiss_groups != "string" ? data.permiss_groups : [];
      this.tearmCheckedList =
        typeof data.permiss_users != "string" ? data.permiss_users : [];
      this.tearmTableChoiceNumber = this.tearmCheckedList.length;
      this.activityInfoCreate.group_id = data.group_id ? data.group_id : 0;

      if (!this.isGroup) {
        this.teachingGroupList.forEach((item) => {
          if (item.id == this.activityInfoCreate.group_id) {
            this.groupName = item.name;
          }
        });
      }

      sessionStorage.setItem("research_activity_type_id", data.type);
      if (data.stage_id) {
        this.getCourse(data.stage_id);
      }
    },
    //提交活动信息
    async submitActivityInfo(type) {
      const startTime = this.activityInfoCreate.activityInfoTime[0].split(" ");
      const endTime = this.activityInfoCreate.activityInfoTime[1].split(" ");
      this.activityInfoCreate.start_date = startTime[0];
      this.activityInfoCreate.start_time = startTime[1];
      this.activityInfoCreate.end_date = endTime[0];
      this.activityInfoCreate.end_time = endTime[1];
      this.activityInfoCreate.type = this.activityType;
      if (this.activityInfoCreate.permiss_range == 4) {
        // 允许参与的成员
        if (!this.tearmCheckedList.length) {
          return this.$message.warning("请选择允许参与的成员");
        }
        this.activityInfoCreate.permiss_users = this.tearmCheckedList;
      }
      if (
        !this.activityInfoCreate.activityInfoCover &&
        !this.activityInfoCreate.cover_id
      ) {
        this.activityInfoCreate.activityInfoCover = this.uploadImgDefault.url;
        this.activityInfoCreate.cover_id = this.uploadImgDefault.id;
      }

      sessionStorage.setItem(
        "research_activity_mode_type",
        this.activityInfoCreate.implement_type
      ); //开展方式

      if (type == "add") {
        //新增活动信息接口
        let frormObj = deepClone(this.activityInfoCreate);
        frormObj["is_personid"] = this.activityInfoCreate.is_personid;
        const res = await this.$axios.post("/activity", frormObj);
        if (res.data.code == 900) {
          sessionStorage.setItem("research_activity_id", res.data.data.id);
          this.$emit("saveCallback", true, frormObj);
          this.goActivity(res.data.data.id);
        }
      }
      if (type == "edit") {
        //修改活动信息接口
        let frormObj = deepClone(this.activityInfoCreate);
        frormObj["is_personid"] = this.activityInfoCreate.is_personid;
        const res = await this.$axios.put(`/activity/${this.activityId}`, frormObj);
        if (res.data.code == 900) {
          sessionStorage.setItem(
            "research_activity_id",
            this.$route.query.id || this.activityId
          );
          this.$emit("saveCallback", true, frormObj);
          this.goActivity(this.activityId);
        }
      }
    },
    //学段更改的回调
    sectionChange(id) {
      this.getCourse(id);
    },
    //发起人更改的回调
    sponsorChange(id) {
      this.activityInfoCreate.group_id = "";
      this.activityInfoCreate.teaching_studio_id = "";
      this.getTeachingGroup(id);
      this.groupListShow = true;
      this.activityInfoCreate.is_personid = id - 1;
      this.tearmTableShow = false;
      this.groupName = "";
      //this.$refs["activityInfoCreateForm"].validateField("initiator_type")
      // if (id == 2) {
      //   this.groupListShow = true
      // } else {
      //   if (!this.isGroup) {
      //     this.activityInfoCreate.group_id = 0
      //     this.groupName = ""
      //   }
      //   this.groupListShow = false
      // }
    },
    //发起人组更换按钮
    sponsorGroupButton() {
      this.groupListShow = true;
    },
    //选择组更改的回调
    groupChange(id) {
      this.teachingGroupList.forEach((item) => {
        if (item.id == id) {
          this.groupName = item.name || item.teaching_studio_name;
          this.groupListShow = false;
        }
      });
    },
    //选择指定成员回调
    tearmTableChecked(list) {
      this.tearmCheckedList = list;
      this.tearmTableChoiceNumber = this.tearmCheckedList.length;
      this.$refs["activityInfoCreateForm"].validateField("permiss_users");
    },
    //保存
    save() {
      this.$refs["activityInfoCreateForm"].validate((valid) => {
        if (valid) {
          this.submitActivityInfo(this.submitType);
        }
      });
    },
    //选择指定成员
    confirmEvent(isOk, list) {
      if (isOk && Array.isArray(list)) {
        this.tearmCheckedList = list;
        this.tearmTableChoiceNumber = this.tearmCheckedList.length;
      }
      this.tearmTableShow = false;
      this.$refs["activityInfoCreateForm"].validateField("permiss_users"); //单独校验
    },
    tearmTable() {
      if (
        !this.activityInfoCreate.group_id &&
        !this.activityInfoCreate.teaching_studio_id
      ) {
        this.$message.warning("请选择活动发起人");
        return false;
      }
      this.tearmTableShow = true;
    },
    //返回
    toBack() {
      this.$router.go(-1);
    },
    //进入活动
    goActivity(id) {
      let query = {
        activityId: id,
        is_from: this.activityInfoCreate.is_personid,
      };
      this.$router.push({
        path: `/basicinfo${queryString(query)}`,
        query: {},
      });
    },
  },
  watch: {
    //监听环节描述
    "activityInfoCreate.description"() {
      this.$nextTick(() => {
        this.$refs["activityInfoCreateForm"].validateField("description");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.public-activity-box {
  width: 1400px;
  margin: auto;
}

.public-activity-box-studio {
  width: 100%;

  .public-main-box {
    border-radius: 8px;
  }
}

.public-breadcrumb {
  height: 48px;
  line-height: 48px;

  .breadcrumb-before {
    font-size: 14px;
    color: #666;

    a {
      font-size: 14px;
      color: #666;
    }
  }

  .breadcrumb-current {
    font-size: 14px;
    color: #ff8201;
  }
}

.public-main-box {
  background: #fff;
  border-radius: 12px;
}

.activity-steps-box {
  display: flex;
  justify-content: center;
  padding: 50px 0 20px;

  .nextBox,
  .backBox {
    height: 50px;
    background: #3489fe;
    border-radius: 4px;
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    line-height: 50px;
    padding: 0 30px;
    display: inline-block;
    cursor: pointer;
  }

  .backBox {
    background: #b6becd;
  }
}

.title {
  padding: 40px 0 40px 46px;
  font-size: 20px;
  color: #333333;
}

/deep/ .activity-el-form {
  width: 100%;
  margin: 0;

  .permiss {
    display: flex;

    .el-form-item__content {
      display: flex;
      align-items: center;
      margin-left: 0 !important;
    }
  }
}

.upload-box {
  display: flex;

  .upload-img-box {
    width: 134px;
    height: 75px;
    background: #f7f7f7;
    border: 1px solid #ececec;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;

    .upload-img {
      width: 134px;
      height: 75px;
    }

    .upload-noData {
      font-size: 30px;
    }
  }

  .upload-img-activity {
    border: 4px solid #409eff;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "选中";
      display: block;
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      position: absolute;
    }
  }

  //三张图片样式
  .box_img1 {
    .dg {
      z-index: 1;
      position: absolute;
      margin-left: -83px;
      margin-top: 18px;
      font-size: 38px;
      color: #f7f7f7;
    }

    background: #000;
    width: 134px;
    height: 75px;
    margin-right: 5px;
    //

    img {
      position: relative;
      z-index: 2;
      width: 134px;
      height: 75px;
      opacity: 0.6;
    }
  }

  // 没有打钩的样式
  .box_img2 {
    .dg {
      display: none;
    }

    width: 134px;
    height: 75px;
    margin-right: 5px;

    img {
      position: relative;
      z-index: 2;
      width: 134px;
      height: 75px;
    }
  }
}

.list-box {
  width: 500px;
  height: 100px;
  padding: 15px;
  border: 1px solid #dcdfe6;
  overflow-y: auto;
}

.tearm-box {
  width: 500px;
  height: 100px;
  border: 1px solid #dcdfe6;
  display: flex;

  .box-operate {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-right: 1px solid #dcdfe6;
  }

  .box-tag {
    flex: 1;
    padding: 15px;
    overflow-y: auto;
  }
}

.sponsor-box {
  display: inline-flex;
  align-items: center;

  .box-right {
    font-size: 14px;
    display: flex;
    margin-left: 50px;

    .sponsor-button {
      color: #409eff;
      cursor: pointer;
      margin-left: 15px;
    }
  }
}

.participation-box {
  display: flex;
  margin-left: 20px;

  .box-left {
    margin-right: 15px;
  }

  .box-right {
    flex: 1;
    overflow: hidden;

    .participation-people {
      font-size: 14px;
      color: #606266;
      cursor: pointer;
      display: inline;

      span {
        color: #f56c6c;
        margin: 0 4px;
      }
    }
  }
}
</style>
